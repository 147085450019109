
$(document).ready(function() {

  var config = {
    '.chosen-select'           : {},
    '.chosen-select-deselect'  : { allow_single_deselect: true },
    '.chosen-select-no-single' : { disable_search_threshold: 10 },
    '.chosen-select-no-results': { no_results_text: 'Oops, nothing found!' },
    '.chosen-select-rtl'       : { rtl: true },
    '.chosen-select-width'     : { width: '100%',display_selected_options:false },
  }
  for (var selector in config) {
    $(selector).chosen(config[selector]);
  }

  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="bstooltip"]').bstooltip();
  if ($("#searchInput").val()) {
    $(".close-icon").show();
  }

  // Load related user for create relationship b/w volunteers and participants



  // Closes first registration popup and redirect to login page
  $("#btnRegisterPopup")
      .unbind("click")
      .bind("click", function() {
        var loginUrl = $(this).data("url");
        location.replace(loginUrl);
      });

  // Show user notitie in modal
  $(".user-note")
      .unbind("click")
      .bind("click", function() {
        var userId = $(this).data("id");
        $("#notitieModal .note").val("");

        $.ajax({
          method: "GET",
          url: "/overzicht/user/note",
          data: {
            userId: userId
          },
          beforeSend: function() {
            showModalSpinner();
          },
          success: function(response) {
            response = $.parseJSON(response);
            $("#notitieModal .note").val(response.note);
            $("#notitieModal .note-user-id").val(userId);
            $("#notitieModal").modal();

            hideModalSpinner();
          },
          error: function(jqXHR, textStatus, errorThrown) {
            hideModalSpinner();
          }
        });
      });

  $("#update-note")
      .unbind("click")
      .bind("click", function() {
        var note = $("#notitieModal .note").val();
        var userId = $("#notitieModal .note-user-id").val();
        $.ajax({
          method: "POST",
          url: "/overzicht/update/note",
          data: {
            note: note,
            userId: userId
          },
          headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
          },
          beforeSend: function() {
            showModalSpinner();
          },
          success: function(response) {
            response = $.parseJSON(response);
            $("#td-" + userId).html(response.note);
            hideModalSpinner();
            $("#notitieModal #cancel-note").click();
          },
          error: function(jqXHR, textStatus, errorThrown) {
            hideModalSpinner();
          }
        });
      });

  // On click on user bio button
  $(".panel-user .btn-bio")
      .unbind("click")
      .bind("click", function() {
        openBioModal($(this).data("type"), $(this).data("id"));
      });

  // Default datepickerOptions
  datepickerOptions = {
    autoclose: true,
    format: "dd-mm-yyyy",
    language: "nl",
    zIndexOffset: 100000
  };

  // Default timepickerOptions
  timepickerOptions = {
    timeFormat: "H:i",
    minTime: "07:00",
    maxTime: "22:00",
    disableTextInput: true
  };

  // disabled on request (04-09-2020)
  // if user is logged in
  // if(typeof(userType) != "undefined"){
  //   checkForNotifications();
  // }

  // Timepickers for the availability form
  $(".availability .start").timepicker(timepickerOptions);
  $(".availability .end").timepicker(timepickerOptions);
  $(".availability .start").change(function() {
    $(this)
        .closest(".form-group")
        .find(".checkbox input")
        .prop("checked", true);
  });
  $(".availability .end").change(function() {
    $(this)
        .closest(".form-group")
        .find(".checkbox input")
        .prop("checked", true);
  });
  $(".availability .checkbox input").change(function() {
    if (!$(this).prop("checked")) {
      $(this)
          .closest(".form-group")
          .find(".start")
          .val(null);
      $(this)
          .closest(".form-group")
          .find(".end")
          .val(null);
    }
  });
  $(".availability").datepair();

  $(".date").datepicker(datepickerOptions);

  $('.dateOfBirth').daterangepicker({
    singleDatePicker: true,
    showDropdowns: true,
    minYear: 1901,
    // maxYear: parseInt(moment().format('YYYY'),10),
  }, function(start, end, label) {
    // var years = moment().diff(start, 'years');
    // alert("You are " + years + " years old!");
  });
  // On selecting a timerange in the calendar
  onCalendarSelect = function(start, end) {
    // Initially empty recurring and recurring end date
    $("#recurring").val("");
    $("#recurringEndDate").val("");
    // Initially fide recurring end date if not hidden
    if(!$('#divRecurringEndDate').hasClass('hide')){
      $('#divRecurringEndDate').addClass('hide');
    }
    var date = start.format("L");
    var startTime = start.format("LTS");
    var endTime = end.format("LTS");

    var minMoment = moment(date + " 07:00", "DD-MM-YYYY HH:mm").format("x");
    var maxMoment = moment(date + " 22:00", "DD-MM-YYYY HH:mm").format("x");
    var startMoment = moment(date + " " + startTime, "DD-MM-YYYY HH:mm").format(
        "x"
    );
    var endMoment = moment(date + " " + startTime, "DD-MM-YYYY HH:mm").format(
        "x"
    );

    if (minMoment > startMoment || maxMoment < startMoment) {
      var startTime = "07:00";
    }
    if (minMoment > endMoment || maxMoment < endMoment) {
      var endTime = "08:00";
    }

    openRequestModal(date, startTime, endTime);
  };

  // Open Bio in modal
  openBioModal = function(type, id) {
    // Clean the bio model from previously added data
    $("#bioModal .bio-title").html("");
    $(".user-bio-summary").html("");

    $("#bioModal").modal();

    showModalSpinner();

    $.ajax({
      method: "GET",
      url: "/overzicht/jsonbio",
      data: {
        type: type,
        id: id
      },
      success: function(result) {
        result = $.parseJSON(result);

        switch (type) {
          case "volunteer":
          case "participant":
            $("#bioModal .bio-title").html(
                result.firstName + " " + result.lastName
            );
            break;
          case "bicycle":
            $("#bioModal .bio-title").html(result.title);
            break;
        }

        // Put default message if bio is not available
        if (result.bio == "" || result.bio == null) {
          $(".user-bio-summary").html("Geen gegevens ingevuld");
        } else {
          $(".user-bio-summary").html(result.bio);
        }

        hideModalSpinner();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        hideModalSpinner();
      }
    });
  };

  // Open the request modal
  openRequestModal = function(date, startTime, endTime) {
    $("#requestModal .date")
        .val(date)
        .datepicker(datepickerOptions);
    $("#requestModal .start")
        .val(startTime)
        .timepicker(timepickerOptions);
    $("#requestModal .end")
        .val(endTime)
        .timepicker(timepickerOptions);
    $("#requestModal").datepair();

    $("#requestModal").modal();


    updateRequestAvailabilities();
  };

  // Listen for changes in any of the date or time inputs for an appointment
  $(document).delegate("#requestModal .date", "change", function() {
    updateRequestAvailabilities();
  });
  $(document).delegate("#requestModal .start", "change", function() {
    updateRequestAvailabilities();
  });
  $(document).delegate("#requestModal .end", "change", function() {
    updateRequestAvailabilities();
  });

  // Listen for the submit button being clicked for an appointment
  $(document).delegate("#requestModal #appointmentSubmit", "click", function() {
    validateRequest(true);
  });

  // Get the available participants, volunteers and bicycles
  updateRequestAvailabilities = function() {
    currentParticipant_id = 0;
    currentSubParticipant_id = 0;
    currentVolunteer_id = 0;
    currentBicycle_id = 0;

    showModalSpinner();

    $("#requestModal #validation_alert").hide();
    $("#requestModal #appointmentCreationMessage").val("");

    var data = {
      date: $("#requestModal .date").val(),
      startTime: $("#requestModal .start").val(),
      endTime: $("#requestModal .end").val(),
      userType: userType,
      recurring : "",
      recurringEndDate: "",
    };

    if (userType == "participant") {
      currentVolunteer_id = $("#requestModal #volunteer_id").val();
      //$("#requestModal #volunteer_id").prop("disabled", true);
    }

    if (userType == "volunteer") {
      currentParticipant_id = $("#requestModal #participant_id").val();
      //$("#requestModal #participant_id").prop("disabled", true);
    }

    if (userIsAdministrator == "1") {
      currentVolunteer_id = $("#requestModal #volunteer_id").val();
      currentParticipant_id = $("#requestModal #participant_id").val();
    }

    currentBicycle_id = $("#requestModal #bicycle_id").val();
    //$("#requestModal #bicycle_id").prop("disabled", true);

    $.ajax({
      method: "GET",
      url: "/overzicht/afspraakbeschikbaarheid",
      data: data,
      success: function(response) {
        if (userType == "participant" || userIsAdministrator == "1") {
          $("#requestModal #subparticipants_alert").hide(); 
          $("#requestModal #subparticipant_id").val(0);
          if (typeof response.subUsers != "undefined" && response.subUsers.length) {
            $("#requestModal #subparticipant_id").html("");
            $.each(response.subUsers, function(i, subparticipant) {
              var name = "--- " + subparticipant.firstName + " " + subparticipant.lastName;
              if (subparticipant.isAvailable == 0 && subparticipant.id != user_id) {
                $("#requestModal #subparticipant_id").append(
                    $("<option></option>")
                        .attr("class", "not-available")
                        .attr("value", subparticipant.id)
                        .text(name)
                );
              } else {
                $("#requestModal #subparticipant_id").append(
                    $("<option>", {
                      value: subparticipant.id,
                      text: name
                    })
                );
              }
            });
          } else {
            $("#requestModal #subparticipants_alert").show();
          }


          $("#requestModal #volunteers_alert").hide();
          $("#requestModal #volunteer_id").val(0);
          if (response.volunteers.length) {
            $("#requestModal #volunteer_id").html("");
            $("#requestModal #volunteer_id").append(
                $("<option>", {
                  value: 0,
                  text: "Kies een " + Lang.get("custom.volunteer") + ".."
                })
            );
            $.each(response.volunteers, function(i, volunteer) {
              var name = volunteer.firstName + " " + volunteer.lastName;
              if (volunteer.isAvailable == 0 && volunteer.id != user_id) {
                $("#requestModal #volunteer_id").append(
                    $("<option></option>")
                        .attr("class", "not-available")
                        .attr("value", volunteer.id)
                        .text(name)
                );
              } else {
                $("#requestModal #volunteer_id").append(
                    $("<option>", {
                      value: volunteer.id,
                      text: name
                    })
                );
              }
            });
            // $("#requestModal #volunteer_id").val(currentVolunteer_id);

          } else {
            $("#requestModal #volunteers_alert").show();
          }
        }

        if (userType == "volunteer" || userIsAdministrator == "1") {
          $("#requestModal #participants_alert").hide();
          $("#requestModal #participant_id").val(0);
          if (response.participants.length) {
            $("#requestModal #participant_id").html("");
            $("#requestModal #participant_id").append(
                $("<option>", {
                  value: 0,
                  text: "Kies een " + getLang('participant') + ".."
                })
            );
            $.each(response.participants, function(i, participant) {
              var name = participant.firstName + " " + participant.lastName;
              if (participant.isAvailable == 0 && participant.id != user_id) {
                $("#requestModal #participant_id").append(
                    //$("<option></option>").attr("data-thumbnail", "http://samenfietsen.test/images/not-available.png").attr("value", participant.id).text(name));
                    $("<option></option>")
                        .attr("class", "not-available")
                        .attr("value", participant.id)
                        .text(name)
                );
              } else {
                $("#requestModal #participant_id").append(
                    $("<option>", {
                      value: participant.id,
                      text: name
                    })
                );
              }
            });
            // $("#requestModal #participant_id").val(currentParticipant_id);
            //$("#requestModal #participant_id").prop("disabled", false);
          } else {
            $("#requestModal #participants_alert").show();
          }
        }

        $("#requestModal #bicycles_alert").hide();
        $("#requestModal #bicycle_id").val(0);

        if (response.bicycles.length) {
          $("#requestModal #bicycle_id").html("");
          $("#requestModal #bicycle_id").append(
              $("<option>", {
                value: 0,
                text: "Kies een " + Lang.get("custom.bicycle") + ".."
              })
          );
          $.each(response.bicycles, function(i, bicycle) {
            $("#requestModal #bicycle_id").append(
                $("<option>", {
                  value: bicycle.id,
                  text: bicycle.title
                })
            );
          });
          $("#requestModal #bicycle_id").val(currentBicycle_id);
          //$("#requestModal #bicycle_id").prop("disabled", false);
        } else {
          $("#requestModal #bicycles_alert").show();
        }

        $("#requestModal #participant_id").addClass("selectpicker");
        $("#requestModal #volunteer_id").addClass("selectpicker");
        // $("#requestModal #bicycle_id").addClass("selectpicker");
        $(".selectpicker").selectpicker("refresh");

        hideModalSpinner();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        hideModalSpinner();
      }
    });
  };

  // Check if all request data is correct
  validateRequest = function(submit) {
    $("#requestModal .alert").hide();

    showModalSpinner();

    var data = {
      date: $("#requestModal .date").val(),
      startTime: $("#requestModal .start").val(),
      endTime: $("#requestModal .end").val(),
      recurring: $("#recurring").val(),
      recurringEndDate: $("#recurringEndDate").val(),
      appointmentCreationMessage : $("#requestModal #appointmentCreationMessage").val()
    };

    var result = { available: true, message: "" };

    if ($("#requestModal #bicycle_id").val() == 0) {
      result.available = false;
      result.message = "Er is nog geen " + Lang.get("custom.bicycle") + " gekozen";
    }

    if (
        result.available &&
        userType == "participant" &&
        $("#requestModal #volunteer_id").val() == 0
    ) {
      result.available = false;
      result.message = "Er is nog geen " + Lang.get("custom.volunteer") + " gekozen";
    }

    if (
        result.available &&
        userType == "volunteer" &&
        $("#requestModal #participant_id").val() == 0
    ) {
      result.available = false;
      result.message = "Er is nog geen " +getLang("participant") + " gekozen";
    }

    if (
        result.available &&
        moment(data.date + " " + data.startTime, "DD-MM-YYYY HH:mm").format("x") ===
        moment(data.date + " " + data.endTime, "DD-MM-YYYY HH:mm").format("x")
    ) {
      result.available = false;
      result.message = "Deze afspraak is niet mogelijk, kies een verschillende begin- of eindtijd";
    }

    if (
        result.available &&
        userIsAdministrator != "1" &&
        moment().format("x") >
        moment(data.date + " " + data.startTime, "DD-MM-YYYY HH:mm").format("x")
    ) {
      result.available = false;
      result.message = "De afspraak ligt in het verleden";
    }

    if ( result.available && userIsAdministrator == "1"){
      if (userType == "participant" && $("#requestModal #participant_id").val() == 0) {
        result.available = false;
        result.message = "Er is nog geen " +getLang("participant") + " gekozen";
      }

      if (userType == "volunteer" && $("#requestModal #volunteer_id").val() == 0) {
        result.available = false;
        result.message = "Er is nog geen " + Lang.get("custom.volunteer") + " gekozen";
      }
    }

    if (userType == "participant" && $("#requestModal #subparticipant_id").val() == 0) {
      result.available = false;
      result.message = "Er is nog geen " + Lang.get("custom.subparticipant") + " gekozen";
    }

    if (result.available) {
      if (userType == "participant") {
        data.volunteer_id = $("#requestModal #volunteer_id").val();
        if (userIsAdministrator == "1") {
          data.participant_id = $("#requestModal #participant_id").val();
        } else {
          if(typeof($("#requestModal #subparticipant_id").val()) != 'undefined' && $("#requestModal #subparticipant_id").val() > 0){
            data.participant_id = $("#requestModal #subparticipant_id").val();
          }else{
            data.participant_id = user_id;
          }
        }
      }

      if (userType == "volunteer") {
        data.participant_id = $("#requestModal #participant_id").val();
        if (userIsAdministrator == "1") {
          data.volunteer_id = $("#requestModal #volunteer_id").val();
        } else {
          data.volunteer_id = user_id;
        }

        if (
            $("#requestModal #approvedByParticipant:checkbox:checked").length > 0
        ) {
          data.approvedByParticipant = 1;
        }
      }

      data.bicycle_id = $("#requestModal #bicycle_id").val();

      if(data.recurring != "") {
        if(!data.recurringEndDate) {
          hideModalSpinner();
          result.message = 'Vul een einddatum in.'
          $("#validation_alert")
              .html("<strong>Let op: </strong>" + result.message)
              .show();
          return false;
        }
      } else {
        $("#validation_alert").html("").hide();
      }

      $.ajax({
        method: "GET",
        url: "/overzicht/afspraakcheck",
        data: data,
        success: function(result) {
          hideModalSpinner();
          if (!result.available) {
            $("#validation_alert")
                .html("<strong>Let op: </strong>" + result.message)
                .show();
          } else {
            if (submit) {
              submitRequest(data);
            }
          }
        },
        error: function(jqXHR, textStatus, errorThrown) {
          hideModalSpinner();
        }
      });
    } else {
      hideModalSpinner();
      $("#validation_alert")
          .html("<strong>Let op: </strong>" + result.message)
          .show();
    }
  };

  // Save the appointment
  submitRequest = function(data) {
    showModalSpinner();
    $.ajax({
      method: "POST",
      url: "/overzicht/afspraken",
      data: data,
      success: function(response) {
        $("#requestModal .btn-cancel").click();
        hideModalSpinner();
        $("#fullcalendar").fullCalendar(
            "gotoDate",
            moment(data.date, "DD-MM-YYYY")
        );
        $("#fullcalendar").fullCalendar("refetchEvents");
      },
      error: function(jqXHR, textStatus, errorThrown) {
        hideModalSpinner();
      }
    });
  };

  $(document).delegate("#requestModal #appointmentCancel", "click", function() {
    hideModalSpinner();
  });

  // On notification click
  $(document).on("click", "li.notification", function() {
    var type = $(this).data("type");

    if (type == "appointment" || type == "message") {
      var event = {
        appointment_id: $(this).data("appointmentid"),
        date: $(this).data("date"),
        startTime: $(this).data("starttime"),
        endTime: $(this).data("endtime"),
        participantName: $(this).data("participantname"),
        approvedByParticipant: $(this).data("approvedbyparticipant"),
        volunteerName: $(this).data("volunteername"),
        approvedByVolunteer: $(this).data("approvedbyvolunteer"),
        bicycleTitle: $(this).data("bicycletitle"),
        viewable: true
      };

      onCalendarEventClick(event, true);
    }

    if (type == "user") {
      window.location.href = "/overzicht/registraties?notificaties="+$(this).data('userid');
    }
  });


  // Deactivate sub participant
  $(".subparticipant-status")
      .unbind("click")
      .bind("click", function() {
        activateOrDeactiveSubParticipant(
            $(this).data("active"),
            $(this).data("subpartid")
        );
      });

  activateOrDeactiveSubParticipant = function(active, subpartid) {
    $.ajax({
      method: "POST",
      url: "/overzicht/subdeelnemer/status",
      data: {
        preStatus: active,
        id: subpartid
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      beforeSend: function() {
        $("#loader" + subpartid).show();
      },
      success: function(result) {
        if (active == 1) {
          $("#subde" + subpartid).hide();
          $("#subact" + subpartid).show();
        } else {
          $("#subact" + subpartid).hide();
          $("#subde" + subpartid).show();
        }

        $("#loader" + subpartid).hide();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        //
      }
    });
  };

  // Check recurring selected value and show end date field
  $("#recurring").change(function() {
    var selectedValue = $('#recurring').val();
    if(selectedValue == 'weekly' || selectedValue == 'biweekly'){
      $('#divRecurringEndDate').removeClass('hide');
    }else{
      $('#divRecurringEndDate').addClass('hide');
    }
  });
  var orgMaxWeeksRecurring = $('#orgMaxWeeksRecurring').val();
  var startRecurringDate = moment().add(1, 'week').format('DD-MM-YYYY');
  var endRecurringDate = moment().add(orgMaxWeeksRecurring, 'week').format('DD-MM-YYYY');

  var endDateOptions = {
    autoclose: true,
    format: "dd-mm-yyyy",
    language: "nl",
    viewMode: 'years',
    minDate: startRecurringDate,
    maxDate: endRecurringDate,
    singleDatePicker: true,
  };
  $(".endDate").daterangepicker(endDateOptions);

  // On click on icon behind every filter option
  $("#filters div.filter-item i")
      .unbind("click")
      .bind("click", function() {
        openAvailabilityModal($(this).data("type"), $(this).data("id"));
      });

  // On click on user availability button
  $(".panel-user .btn-availability")
      .unbind("click")
      .bind("click", function() {
        openAvailabilityModal($(this).data("type"), $(this).data("id"));
      });

  // Open availability in modal
  openAvailabilityModal = function(type, id) {
    $("#availabilityModal").modal();

    showModalSpinner();

    $.ajax({
      method: "GET",
      url: "/overzicht/jsonbeschikbaarheid",
      data: {
        type: type,
        id: id
      },
      success: function(result) {
        result = $.parseJSON(result);
        switch (type) {
          case "volunteer":
          case "participant":
            $("#availabilityModal .availability-title").html(
                result.firstName + " " + result.lastName
            );
            if (result.logged_in_user_id == id) {
              $(".accept-request-container").removeClass('hide');
            } else {
              $(".accept-request-container").addClass('hide');
            }
            break;
          case "bicycle":
            $("#availabilityModal .availability-title").html(result.title);
            break;
        }

        $(".availabilityEmail").html(result.email !="" ? result.email : '');
        $(".availabilityPhone").html(result.phone !="" ? result.phone : '');
        $(".availabilityMobile").html(result.mobile !="" ? result.mobile : '');
        $(".availabilityBio").html(result.bio !== null && result.bio != ""  && result.bio !== undefined ? result.bio : 'Geen gegevens ingevuld');

        document.getElementById("profile-img").src=result.avatar;


        if (result.monday_available === 1) {
          $(".monday_start").html(result.monday_start);
          $(".monday_end").html(result.monday_end);
        } else {
          $(".monday_start").html(' ');
          $(".monday_end").html(' ');
        }
        if (result.tuesday_available === 1) {
          $(".tuesday_start").html(result.tuesday_start);
          $(".tuesday_end").html(result.tuesday_end);

        } else {
          $(".tuesday_start").html(' ');
          $(".tuesday_end").html(' ');
        }
        if (result.wednesday_available === 1) {
          $(".wednesday_start").html(result.wednesday_start);
          $(".wednesday_end").html(result.wednesday_end);

        } else {
          $(".wednesday_start").html(' ');
          $(".wednesday_end").html(' ');
        }
        if (result.thursday_available === 1) {
          $(".thursday_start").html(result.thursday_start);
          $(".thursday_end").html(result.thursday_end);

        } else {
          $(".thursday_start").html(' ');
          $(".thursday_end").html(' ');
        }
        if (result.friday_available === 1) {
          $(".friday_start").html(result.friday_start);
          $(".friday_end").html(result.friday_end);
        } else {
          $(".friday_start").html(' ');
          $(".friday_end").html(' ');
        }
        if (result.saturday_available === 1) {
          $(".saturday_start").html(result.saturday_start);
          $(".saturday_end").html(result.saturday_end);
        } else {
          $(".saturday_start").html(' ');
          $(".saturday_end").html(' ');
        }
        if (result.sunday_available === 1) {
          $(".sunday_start").html(result.sunday_start);
          $(".sunday_end").html(result.sunday_end);
        } else {
          $(".sunday_start").html(' ');
          $(".sunday_end").html(' ');
        }

        if(result.spontaneousRequests) {
          $("#acceptRequest").attr("checked", "checked");
        } else {
          $("#acceptRequest").removeAttr("checked");
        }

        if(result.isBicycle) {
          $(".accept-request-container").hide();
        } else {
          $(".accept-request-container").show();
        }

        hideModalSpinner();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        hideModalSpinner();
      }
    });
  };

  $(".btn-add-appointment")
      .unbind("click")
      .click(function() {
        var m = moment(new Date(), "DD-MM-YYYY");
        var roundUp =
            m.minute() || m.second() || m.millisecond()
                ? m.add(1, "hour").startOf("hour")
                : m.startOf("hour");
        $("#fullcalendar").fullCalendar("select", roundUp);
      });

  // Open the modal for a new appointment
  onCalendarEventClick = function(event, fromNotification) {
    if (fromNotification) {
      var date = event.date;
      var startTime = event.startTime;
      var endTime = event.endTime;
      $("#fullcalendar").fullCalendar("gotoDate", moment(date, "DD-MM-YYYY"));
    } else {
      var date = event.start.format("DD-MM-YYYY");
      var startTime = event.start.format("HH:mm");
      var endTime = event.end.format("HH:mm");
    }

    hideModalSpinner();

    $("#appointmentModal #validation_alert_appointment").hide();

    if (event.viewable || userIsAdministrator == "1") {
      $("#appointmentModal").modal();

      $(".appointment-cancel-confirm").hide();
      $(".appointment-cancel").show();
      $(".appointment-cancel-reason").val("");

      $(".appointment-reject-confirm").hide();
      $(".appointment-reject").show();
      $(".appointment-approve").show();
      $(".appointment-reject-reason").val("");

      $(".request-cancel-confirm").hide();
      $(".request-cancel").show();
      $(".request-cancel-reason").val("");

      $("#appointmentModal .appointment-date").html(date);
      $("#appointmentModal .appointment-time").html(
          startTime + " - " + endTime
      );

      if (userType == "participant" || userIsAdministrator == "1") {
        $("#appointmentModal .appointment-volunteer").html(event.volunteerName);
      }

      if (event.participantIsSubUser === 1) {
        $("#appointmentModal .appointment-participant").html(event.participantName+" (sub-deelnemer)");
      } else if (userType == "volunteer" || userIsAdministrator == "1") {
        $("#appointmentModal .appointment-participant").html(
            event.participantName
        )
      }



      $("#appointmentModal .appointment-bicycle").html(event.bicycleTitle);

      $("#appointmentModal .appointment-creation-message").html(event.appointmentCreationMessage);

      $("#appointmentModal .appointment-status-other-approved").hide();
      $("#appointmentModal .appointment-status-you-approved").hide();
      $("#appointmentModal .appointment-status-both-approved").hide();
      $("#appointmentModal .appointment-status-volunteer-approved").hide();
      $("#appointmentModal .appointment-status-participant-approved").hide();

      if (userIsAdministrator === "1") {
        if (event.approvedByVolunteer && !event.approvedByParticipant) {
          $("#appointmentModal .appointment-status-participant-approved").show();
          $("#appointmentModal .appointment-status-other-approved").show()
        } else if (event.approvedByParticipant && !event.approvedByVolunteer) {
          $("#appointmentModal .appointment-status-volunteer-approved").show();
          $("#appointmentModal .appointment-status-other-approved").show()
        } else if (event.approvedByParticipant && event.approvedByVolunteer) {
          $("#appointmentModal .appointment-status-both-approved").show();
        }
      } else {
        if (event.approvedByParticipant && event.approvedByVolunteer) {
          $("#appointmentModal .appointment-status-both-approved").show();
        } else if (
            !event.approvedByVolunteer &&
            event.approvedByParticipant &&
            userType == "participant"
        ) {
          $("#appointmentModal .appointment-status-you-approved").show();
        } else if (
            !event.approvedByParticipant &&
            event.approvedByVolunteer &&
            userType == "volunteer"
        ) {
          $("#appointmentModal .appointment-status-you-approved").show();
        } else if (!event.approvedByVolunteer && userType == "volunteer") {
          $("#appointmentModal .appointment-status-other-approved").show();
        } else if (!event.approvedByParticipant && userType == "participant") {
          $("#appointmentModal .appointment-status-other-approved").show();
        }
      }

      $(".appointment-approve")
          .unbind("click")
          .bind("click", function() {
            markAppointmentApproved(event.appointment_id, user_id, userType);
          });

      $(".appointment-reject")
          .unbind("click")
          .bind("click", function(e) {
            $(e.currentTarget).nextAll(".appointment-reject-confirm").show();
            $(e.currentTarget).nextAll(".appointment-reject-confirm").find(".appointment-reject").hide();
            $(e.currentTarget).nextAll(".appointment-reject-confirm").find(".appointment-approve").hide();
            $('#appointmentModal textarea').trigger("keyup.dynSiz")
          });

      $(".appointment-reject-yes")
          .unbind("click")
          .bind("click", function(e) {
            var reason = $(e.currentTarget).prevAll(".appointment-reject-reason").val();
            markAppointmentRejected(
                event.appointment_id,
                user_id,
                userType,
                reason
            );
          });

      $(".appointment-reject-no")
          .unbind("click")
          .bind("click", function(e) {
            $(e.currentTarget).closest(".appointment-reject-confirm").hide();
            $(e.currentTarget).closest(".appointment-reject-confirm").prevAll(".appointment-reject").show();
            $(e.currentTarget).closest(".appointment-reject-confirm").prevAll(".appointment-approve").show();
            $(e.currentTarget).prevAll(".appointment-reject-reason").val("");
          });

      $(".request-cancel")
          .unbind("click")
          .bind("click", function(e) {
            $(e.currentTarget).next(".request-cancel-confirm").show();
            $(e.currentTarget).hide();
            $('#appointmentModal textarea').trigger("keyup.dynSiz")
          });

      $(".request-cancel-yes")
          .unbind("click")
          .bind("click", function(e) {
            var reason = $(e.currentTarget).prevAll(".request-cancel-reason").val();
            markAppointmentCancelled(
                event.appointment_id,
                user_id,
                userType,
                reason
            );
          });

      $(".request-cancel-no")
          .unbind("click")
          .bind("click", function(e) {
            $(e.currentTarget).closest(".request-cancel-confirm").hide();
            $(e.currentTarget).closest(".request-cancel-confirm").prevAll(".request-cancel").show();
            $(e.currentTarget).prevAll(".request-cancel-reason").val("");
          });

      $(".appointment-cancel")
          .unbind("click")
          .bind("click", function(e) {
            if((new Date(event.end) <= $.now() || new Date(event.start) <= $.now()) && userIsAdministrator !== "1")
            {
              $("#validation_alert_appointment")
                  .html("<strong>Let op: </strong>" + "U kunt geen afspraak annuleren in het verleden, neem hiervoor contact op met de coördinator van de fietsritten")
                  .show();
              return
            }
            $(e.currentTarget).nextAll(".appointment-cancel-confirm").show();
            $('textarea').trigger("keyup.dynSiz")
            $(e.currentTarget).hide();
          });

      $(".appointment-cancel-yes")
          .unbind("click")
          .bind("click", function(e) {
            var reason = $(e.currentTarget).prevAll(".appointment-cancel-reason").val();
            markAppointmentCancelled(
                event.appointment_id,
                user_id,
                userType,
                reason
            );
          });

      $(".appointment-cancel-no")
          .unbind("click")
          .bind("click", function(e) {
            $(e.currentTarget).closest(".appointment-cancel-confirm").hide();
            $(e.currentTarget).closest(".appointment-cancel-confirm").prevAll(".appointment-cancel").show();
            $(e.currentTarget).prevAll(".appointment-cancel-reason").val("");
          });

      $("#chat ul.messages").html("");

      $.ajax({
        method: "GET",
        url: "/overzicht/berichten",
        data: { appointment_id: event.appointment_id },
        success: function(messages) {
          if (messages.length) {
            $.each(messages, function(index, message) {
              if (userType == "participant" && message.readByParticipant == 0) {
                markMessageRead(message.id, "participant");
              }
              if (userType == "volunteer" && message.readByVolunteer == 0) {
                markMessageRead(message.id, "volunteer");
              }
              $("#chat ul.messages").append(
                  '<li class="left clearfix"><div class="chat-body clearfix"><img src="' +
                  message.user.avatar +
                  '"><div class="header"><strong class="primary-font">' +
                  message.user.firstName +
                  " " +
                  message.user.lastName +
                  "</strong> | " +
                  message.datetime +
                  "</div><p>" +
                  message.message +
                  "</p></div></li>"
              );
            });
            $("#chat .panel-body").animate(
                { scrollTop: $("#chat .panel-body").prop("scrollHeight") },
                1000
            );
          }
        },
        error: function(jqXHR, textStatus, errorThrown) {
          //
        }
      });

      Echo.private("appointment." + event.appointment_id).listen(
          "MessageSent",
          function(e) {
            $("#chat ul.messages").append(
                '<li class="left clearfix"><div class="chat-body clearfix"><img src="' +
                e.user.avatar +
                '"><div class="header"><strong class="primary-font">' +
                e.user.firstName +
                " " +
                e.user.lastName +
                "</strong> | " +
                moment(e.message.created_at).format("DD-MM-YYYY HH:mm") +
                "</div><p>" +
                e.message.message +
                "</p></div></li>"
            );
            $("#chat .panel-body").animate(
                { scrollTop: $("#chat .panel-body").prop("scrollHeight") },
                1000
            );
            if (userType == "participant") {
              markMessageRead(e.message.id, "participant");
            }
            if (userType == "volunteer") {
              markMessageRead(e.message.id, "volunteer");
            }
          }
      );

      $("#chat-submit")
          .unbind("click")
          .bind("click", function() {
            var message = $("#chat-message").val();
            if (message !== "") {
              $.ajax({
                method: "POST",
                url: "/overzicht/berichten",
                data: {
                  message: message,
                  appointment_id: event.appointment_id
                },
                headers: {
                  "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
                },
                success: function(messages) {
                  $("#chat ul.messages").append(
                      '<li class="left clearfix"><div class="chat-body clearfix"><img src="' +
                      userAvatar +
                      '"><div class="header"><strong class="primary-font">' +
                      userName +
                      "</strong> | " +
                      moment().format("DD-MM-YYYY HH:mm") +
                      "</div><p>" +
                      message +
                      "</p></div></li>"
                  );
                  $("#chat-message").val("");
                  $("#chat .panel-body").animate(
                      { scrollTop: $("#chat .panel-body").prop("scrollHeight") },
                      1000
                  );
                },
                error: function(jqXHR, textStatus, errorThrown) {
                  //
                }
              });
            }
          });
    }
  };

  $("#logout")
      .unbind("click")
      .bind("click", function() {
        $("#confirmLogoutModal").modal();
        $(".modalspinner").hide();
      });

  $("#appointment-cancel")
      .unbind("click")
      .bind("click", function() {
        $("#confirmLogoutModal").modal();
      });

  $(".user-approve")
      .unbind("click")
      .bind("click", function() {
        markUserApproved($(this).data("userid"));
      });

  $(".user-reject")
      .unbind("click")
      .bind("click", function() {
        markUserRejected($(this).data("userid"));
      });

  $(".user-activate")
      .unbind("click")
      .bind("click", function() {
        markUserActivated($(this).data("userid"));
      });

  $(".user-deactivate")
      .unbind("click")
      .bind("click", function() {
        markUserDeactivated($(this).data("userid"));
      });

  $(".user-archive")
      .unbind("click")
      .bind("click", function() {
        markUserArchived($(this).data("userid"));
      });

  $(".user-dearchive")
      .unbind("click")
      .bind("click", function() {
        markUserDearchived($(this).data("userid"));
      });

  $(".user-delete")
      .unbind("click")
      .bind("click", function() {
        deleteUser($(this).data("userid"));
      });

  markMessageRead = function(message_id, userType) {
    $.ajax({
      method: "POST",
      url: "/overzicht/berichtgelezen",
      data: {
        message_id: message_id,
        userType: userType
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      success: function(response) {
        //
      },
      error: function(jqXHR, textStatus, errorThrown) {
        //
      }
    });
  };

  markAppointmentApproved = function(appointment_id, user_id, userType) {
    showModalSpinner();
    $.ajax({
      method: "POST",
      url: "/overzicht/afspraakakkoord",
      data: {
        appointment_id: appointment_id,
        user_id: user_id,
        userType: userType
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      success: function(response) {
        $("#appointmentModal").modal('hide');
        $('#fullcalendar').fullCalendar("refetchEvents");
      },
      error: function(jqXHR, textStatus, errorThrown) {
        hideModalSpinner();
      }
    });
  };

  markAppointmentRejected = function(
      appointment_id,
      user_id,
      userType,
      reason
  ) {
    showModalSpinner();
    $.ajax({
      method: "POST",
      url: "/overzicht/afspraaknietakkoord",
      data: {
        appointment_id: appointment_id,
        user_id: user_id,
        userType: userType,
        reason: reason
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      success: function(response) {
        $("#appointmentModal").modal('hide');
        $('#fullcalendar').fullCalendar("refetchEvents");
      },
      error: function(jqXHR, textStatus, errorThrown) {
        hideModalSpinner();
      }
    });
  };

  markAppointmentCancelled = function(
      appointment_id,
      user_id,
      userType,
      reason
  ) {
    showModalSpinner();
    $.ajax({
      method: "POST",
      url: "/overzicht/afspraakannuleren",
      data: {
        appointment_id: appointment_id,
        user_id: user_id,
        userType: userType,
        reason: reason
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      success: function(response) {
        $("#appointmentModal").modal('hide');
        $('#fullcalendar').fullCalendar("refetchEvents");
      },
      error: function(jqXHR, textStatus, errorThrown) {
        hideModalSpinner();
      }
    });
  };

  markUserApproved = function(user_id) {
    var urlParams = new URLSearchParams(window.location.search);
    $.ajax({
      method: "POST",
      url: "/overzicht/registratieakkoord",
      data: {
        user_id: user_id
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      success: function(response) {
        window.location.href = "/overzicht/registraties?"+urlParams.toString();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        //
      }
    });
  };

  markUserRejected = function(user_id) {
    var urlParams = new URLSearchParams(window.location.search);
    $.ajax({
      method: "POST",
      url: "/overzicht/registratienietakkoord",
      data: {
        user_id: user_id
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      success: function(response) {
        window.location.href = "/overzicht/registraties?"+urlParams.toString();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        //
      }
    });
  };

  markUserActivated = function(user_id) {
    var urlParams = new URLSearchParams(window.location.search);
    $.ajax({
      method: "POST",
      url: "/overzicht/gebruikeractief",
      data: {
        user_id: user_id
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      success: function(response) {
        window.location.href = "/overzicht/registraties?"+urlParams.toString();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        //
      }
    });
  };

  markUserDeactivated = function(user_id) {
    var urlParams = new URLSearchParams(window.location.search);
    $.ajax({
      method: "POST",
      url: "/overzicht/gebruikerinactief",
      data: {
        user_id: user_id
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      success: function(response) {
        window.location.href = "/overzicht/registraties?"+urlParams.toString();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        //
      }
    });
  };

  markUserArchived = function(user_id) {
    var urlParams = new URLSearchParams(window.location.search);
    $.ajax({
      method: "POST",
      url: "/overzicht/gebruikerarchiveren",
      data: {
        user_id: user_id
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      success: function(response) {
        window.location.href = "/overzicht/registraties?"+urlParams.toString();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        //
      }
    });
  };

  markUserDearchived = function(user_id) {
    var urlParams = new URLSearchParams(window.location.search);
    $.ajax({
      method: "POST",
      url: "/overzicht/gebruikerdearchiveren",
      data: {
        user_id: user_id
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      success: function(response) {
        window.location.href = "/overzicht/registraties?"+urlParams.toString();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        //
      }
    });
  };

  deleteUser = function(user_id) {
    var urlParams = new URLSearchParams(window.location.search);
    $.ajax({
      method: "POST",
      url: "/overzicht/gebruikerverwijderen",
      data: {
        user_id: user_id
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      },
      success: function(response) {
        window.location.href = "/overzicht/registraties?"+urlParams.toString();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        //
      }
    });
  };

  // Set the filters based on their cookies
  loadFiltersFromCookie = function() {
    if (typeof Cookies.get("filters-bicycles-"+cacheId) !== "undefined") {
      $(Cookies.get("filters-bicycles-"+cacheId).split("-")).each(function(
          key,
          bicycle_id
      ) {
        $("#filters-bicycles #bicycle_" + bicycle_id).prop("checked", true);
      });
    }

    if (typeof Cookies.get("filters-participants-"+cacheId) !== "undefined") {
      $(Cookies.get("filters-participants-"+cacheId).split("-")).each(function(
          key,
          participant_id
      ) {
        $("#filters-participants #participant_" + participant_id).prop(
            "checked",
            true
        );
      });
    } else {
      Cookies.set("filters-participants-"+cacheId, "", {
        expires: 365,
        path: "/"
      });
    }

    if (typeof Cookies.get("filters-volunteers-"+cacheId) !== "undefined") {
      $(Cookies.get("filters-volunteers-"+cacheId).split("-")).each(function(
          key,
          volunteer_id
      ) {
        $("#filters-volunteers #volunteer_" + volunteer_id).prop(
            "checked",
            true
        );
      });
    } else {
      Cookies.set("filters-volunteers-"+cacheId, "", {
        expires: 365,
        path: "/"
      });
    }

    // Filter changes
    if (
        Cookies.get("filters-requests") === "1" ||
        typeof Cookies.get("filters-requests") === "undefined"
    ) {
      $("#filters-requests #requests").prop("checked", true);
    }
    if (
        Cookies.get("filters-appointments") === "1" ||
        typeof Cookies.get("filters-appointments") === "undefined"
    ) {
      $("#filters-appointments #appointments").prop("checked", true);
    }

    checkInputsSetCollapse('#filters-bicycles');
    checkInputsSetCollapse('#filters-volunteers');
    checkInputsSetCollapse('#filters-participants');
  };

  $("#filters-bicycles input:checkbox")
      .unbind("click")
      .click(function() {
        var bicycle_ids = [];
        $("#filters-bicycles input:checkbox:checked").each(function() {
          bicycle_ids.push($(this).val());
        });
        Cookies.set("filters-bicycles-"+cacheId, bicycle_ids.join("-"), {
          expires: 365,
          path: "/"
        });
        $("#fullcalendar").fullCalendar("refetchEvents");
      });

  $("#filters-participants input:checkbox")
      .unbind("click")
      .click(function() {
        var participant_ids = [];
        $("#filters-participants input:checkbox:checked").each(function() {
          participant_ids.push($(this).val());
        });
        Cookies.set("filters-participants-"+cacheId, participant_ids.join("-"), {
          expires: 365,
          path: "/"
        });
        $("#fullcalendar").fullCalendar("refetchEvents");
      });

  $("#filters-volunteers input:checkbox")
      .unbind("click")
      .click(function() {
        var volunteer_ids = [];
        $("#filters-volunteers input:checkbox:checked").each(function() {
          volunteer_ids.push($(this).val());
        });
        Cookies.set("filters-volunteers-"+cacheId, volunteer_ids.join("-"), {
          expires: 365,
          path: "/"
        });
        $("#fullcalendar").fullCalendar("refetchEvents");
      });

  $("#filters-requests input:checkbox")
      .unbind("click")
      .click(function() {
        var showRequests = 0;
        $("#filters-requests input:checkbox:checked").each(function() {
          showRequests = 1;
        });
        Cookies.set("filters-requests", showRequests, {
          expires: 365,
          path: "/"
        });
        $("#fullcalendar").fullCalendar("refetchEvents");
      });

  $("#filters-appointments input:checkbox")
      .unbind("click")
      .click(function() {
        var showAppointments = 0;
        $("#filters-appointments input:checkbox:checked").each(function() {
          showAppointments = 1;
        });
        Cookies.set("filters-appointments", showAppointments, {
          expires: 365,
          path: "/"
        });
        $("#fullcalendar").fullCalendar("refetchEvents");
      });

  // Spinners
  showCalendarSpinner = function() {
    $("#fullcalendar").addClass("loading");
    $("#filters").addClass("loading");
    $("#filtersoverlay").show();
    $(".calendarspinner").show();
  };

  hideCalendarSpinner = function() {
    $("#fullcalendar").removeClass("loading");
    $("#filters").removeClass("loading");
    $("#filtersoverlay").hide();
    $(".calendarspinner").hide();
  };

  showModalSpinner = function() {
    $(".modal-content-overlay").show();
    $(".modalspinner").show();
  };

  hideModalSpinner = function() {
    $(".modal-content-overlay").hide();
    $(".modalspinner").hide();
  };

  $(".user-filters .panel-heading").click(function() {
    $(this)
        .find("i")
        .toggleClass("fa-check-square");
    if (
        $(this)
            .find('input[type="checkbox"]')
            .is(":checked")
    ) {
      $(this)
          .find('input[type="checkbox"]')
          .attr("checked", false);
    } else {
      $(this)
          .find('input[type="checkbox"]')
          .attr("checked", true);
    }
    showModalSpinner();
    if ($(this).hasClass("registrants")) {
      registrantFilter();
    } else if($(this).hasClass("rapportages")) {
      reportFilter();
    } else {
      userFilter();
    }
  });
});

$(document).on("click", ".search-reset", function() {
  $("#searchInput").val(" ");
  $(".search-reset").hide();
});

$(document).on("change", "#sortDropdown", function() {
  showModalSpinner();
  if ($(this).val() != "") $("#searchUserForm").submit();
});

// File input fix
$(function() {
  $(document).on("change", ":file", function() {
    var input = $(this),
        numFiles = input.get(0).files ? input.get(0).files.length : 1,
        label = input
            .val()
            .replace(/\\/g, "/")
            .replace(/.*\//, "");
    input.trigger("fileselect", [numFiles, label]);
  });

  $(document).ready(function() {
    $(":file").on("fileselect", function(event, numFiles, label) {
      var input = $(this)
              .parents(".input-group")
              .find(":text"),
          log = numFiles > 1 ? numFiles + " files selected" : label;

      if (input.length) {
        input.val(log);
      }
    });
  });
});
function getLang(key) {
  if(key === null || key === undefined || key == "") {
    return Lang.get('custom.'+key);
  }
  return lang[key];
}

function checkForNotifications() {
  $.ajax({
    method: "GET",
    url: "/overzicht/notificaties",
    success: function(response) {
      var notifications = $.parseJSON(response);
      var notificationsNavItem = $("li.dropdown-notifications");
      var notificationsCountIcon = $(
          "li.dropdown-notifications i.notification-icon"
      );
      var notificationsToolbarTitle = $(
          "li.dropdown-notifications h3.dropdown-toolbar-title"
      );
      var notificationsList = $("li.dropdown-notifications ul.dropdown-menu");

      // Handle notification badge and toolbar title
      if (notifications.length) {
        if (notificationsCountIcon.hasClass("notification-icon-hidden")) {
          notificationsCountIcon.removeClass("notification-icon-hidden");
        }
      } else {
        if (!notificationsCountIcon.hasClass("notification-icon-hidden")) {
          notificationsCountIcon.addClass("notification-icon-hidden");
        }
      }
      notificationsCountIcon.attr("data-count", notifications.length);
      notificationsToolbarTitle.html(
          "Notificaties (" + notifications.length + ")"
      );

      // Handle notification dropdown list-style
      if (notifications.length) {
        notificationsList.empty();
        $.each(notifications, function(index, notification) {
          var item = "";
          item += '<li class="notification" ';
          item += 'data-type="' + notification.type + '" ';

          if (
              notification.type === "appointment" ||
              notification.type === "message"
          ) {
            item += 'data-appointmentid="' + notification.appointment.id + '" ';
            item += 'data-date="' + notification.appointment.date + '" ';
            item +=
                'data-starttime="' + notification.appointment.startTime + '" ';
            item += 'data-endtime="' + notification.appointment.endTime + '" ';

            if (userType === "volunteer") {
              item +=
                  'data-participantname="' +
                  notification.appointment.participant.firstName +
                  " " +
                  notification.appointment.participant.lastName +
                  '" ';
            }

            if (userType === "participant") {
              item +=
                  'data-volunteername="' +
                  notification.appointment.volunteer.firstName +
                  " " +
                  notification.appointment.volunteer.lastName +
                  '" ';
            }

            item +=
                'data-approvedbyparticipant="' +
                notification.appointment.approvedByParticipant +
                '" ';
            item +=
                'data-approvedbyvolunteer="' +
                notification.appointment.approvedByVolunteer +
                '" ';
            item +=
                'data-bicycletitle="' +
                notification.appointment.bicycle.title +
                '" ';
          }

          if (notification.type === "user") {
            item += 'data-userid="' + notification.user.id + '" ';
          }

          item += ">";
          item += '<div class="media">';
          item += '<div class="media-left">';
          item += '<div class="media-object">';

          if (
              notification.type === "appointment" ||
              notification.type === "message"
          ) {
            if (userType === "participant") {
              item +=
                  '<img class="img-circle" src="' +
                  notification.appointment.volunteer.avatar +
                  '">';
            }

            if (userType === "volunteer") {
              item +=
                  '<img class="img-circle" src="' +
                  notification.appointment.participant.avatar +
                  '">';
            }
          }

          if (notification.type === "user") {
            item +=
                '<img class="img-circle" src="' + notification.user.avatar + '">';
          }

          item += "</div>";
          item += "</div>";

          item += '<div class="media-body">';

          if (notification.type === "appointment") {
            item += '<strong class="notification-title">Nieuw verzoek van ';

            if (userType === "volunteer") {
              item +=
                  notification.appointment.participant.firstName +
                  " " +
                  notification.appointment.participant.lastName +
                  "</strong>";
            } else {
              item +=
                  notification.appointment.volunteer.firstName +
                  " " +
                  notification.appointment.volunteer.lastName +
                  "</strong>";
            }

            item += '<div class="notification-meta">';
            item += '<small class="timestamp">';
            item +=
                "<strong>" +
                notification.appointment.date +
                "</strong> | " +
                notification.appointment.startTime +
                " - " +
                notification.appointment.endTime;
            item += "</small>";
            item += "</div>";
          }

          if (notification.type === "message") {
            item +=
                '<strong class="notification-title">' +
                notification.message.messageCount;

            if (notification.message.messageCount == 1) {
              item += " nieuw bericht van ";
            } else {
              item += " nieuwe berichten van ";
            }

            if (userType === "volunteer") {
              item +=
                  notification.appointment.participant.firstName +
                  " " +
                  notification.appointment.participant.lastName;
            } else {
              item +=
                  notification.appointment.volunteer.firstName +
                  " " +
                  notification.appointment.volunteer.lastName;
            }
            item += "</strong>";

            item += '<div class="notification-meta">';
            item += '<small class="timestamp">';
            item +=
                "<strong>" +
                notification.message.date +
                "</strong> | " +
                notification.message.time;
            item += "</small>";
            item += "</div>";
          }

          if (notification.type === "user") {
            item +=
                '<strong class="notification-title">Nieuwe registratie: ' +
                notification.user.firstName +
                " " +
                notification.user.lastName +
                "</strong>";

            item += '<div class="notification-meta">';
            item += '<small class="timestamp">';
            item +=
                "<strong>" +
                notification.user.date +
                "</strong> | " +
                notification.user.time;
            item += "</small>";
            item += "</div>";
          }

          item += "</div>";
          item += "</div>";
          item += "</li>";

          notificationsList.append(item);
        });
      } else {
        notificationsList.empty();
      }

      setTimeout(function() {
        checkForNotifications();
      }, 30000);
    }
  });
}

$(document).on("change", "#sort", function(){
  var value = $(this).val();
  showModalSpinner();
  if(value == 'name') {

    $("#name").trigger("click");
    setTimeout(function(){
      hideModalSpinner();
    },1000);

  } else {
    $("#sortIndividualReportByForm").submit();
  }
});

$(document).on("change", ".selected-group-date", function() {
  var fromDate = $("input[name='fromDate']").val();
  toDate = $("input[name='toDate']").val();
  if((fromDate && toDate)) {
    $("#sortReportByForm").submit();
  }
});

$(document).on("change", ".selected-group", function() {
  $("#sortReportByForm").submit();
});






function reportFilter() {
  hideModalSpinner();
  $("#sortReportByForm").submit();

}

function registrantFilter() {

  $("#registrantFilterForm").submit();

  // $.ajax({
  //   method: "GET",
  //   url: "/overzicht/registraties/filter",
  //   data: $(".ids:checked").serialize(),
  //   headers: {
  //     "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
  //   },
  //   success: function(result) {
  //     hideModalSpinner();
  //     $(".registrants-container").html(result.html);
  //   },
  //   error: function(jqXHR, textStatus, errorThrown) {
  //     hideModalSpinner();
  //   }
  // });
}

function userFilter() {
  $.ajax({
    method: "POST",
    url: "/overzicht/smoelenboek-filter",
    data: $(".ids:checked").serialize(),
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
    },
    success: function(result) {
      hideModalSpinner();
      var result = $.map(result, function(value, index) {
        return [value];
      });
      var content = "";
      for (i = 0; i < result.length; i++) {
        if (result[i].type == "bicycle") {
          var name = result[i].title;
          var type = result[i].companyName;
        } else if (result[i].type == "participant") {
          name = result[i].firstName + "&nbsp;" + result[i].lastName;
          type = "Deelnemer";
        } else if (result[i].type == "volunteer") {
          name = result[i].firstName + "&nbsp;" + result[i].lastName;
          type = "Vrijwilliger";
        }
        content +=
            '<div class="col-md-3 sort-user">' +
            '<div class="panel panel-default panel-user">' +
            '<div class="panel-body">' +
            '<span class="name">' +
            name +
            "</span>" +
            '<span class="type"> ' +
            type +
            ' </span><div class="avatar_cont">' +
            '<img class="avatar" src="' +
            result[i].avatar +
            '"></div>' +
            '<span class="label label-success pull-left">Ritten: ' +
            result[i].ritten +
            " </span>" +
            '<span class="label label-info pull-right">Kilometers: ' +
            result[i].kilometers +
            " </span>" +
            '<a data-type="' +
            result[i].type +
            '" data-id="' +
            result[i].id +
            '" class="btn btn-secondary btn-fullwidth btn-bio">Bio</a>' +
            '<a data-type="' +
            result[i].type +
            '" data-id="' +
            result[i].id +
            '" class="btn btn-secondary btn-fullwidth btn-availability">Beschikbaarheid</a>' +
            '<span class="email">' +
            "E-mailadres:<br>" +
            '<a href="mailto:' +
            result[i].email +
            '">' +
            result[i].email +
            "</a>" +
            "</span>" +
            '<span class="phone">' +
            "Telefoonnummer:<br>" +
            '<a href="tel:' +
            result[i].phone +
            '">' +
            result[i].phone +
            "</a>" +
            "</span>" +
            "</div>" +
            "</div>" +
            "</div>";
      }
      $(".user-data").html(content);
      // On click on user availability button
      $(".panel-user .btn-availability")
          .unbind("click")
          .bind("click", function() {
            openAvailabilityModal($(this).data("type"), $(this).data("id"));
          });
    },
    error: function(jqXHR, textStatus, errorThrown) {
      hideModalSpinner();
    }
  });
}

// On hash in url
window.onload = function() {
  if (window.location.href.indexOf("#") > -1) {
    var appointment_id = window.location.href.split("#")[1].split("_")[0];
    var date = moment(
        window.location.href.split("#")[1].split("_")[1],
        "DD-MM-YYYY"
    );
    setTimeout(function() {
      $("#fullcalendar").fullCalendar("gotoDate", date);
      $.each($("li.notification"), function() {
        if ($(this).data("appointmentid") == appointment_id) {
          $(this).trigger("click");
        }
      });
      window.history.pushState("", document.title, window.location.pathname);
    }, 2000);
  }
};

$(function() {
  // Cropper Options set
  var cropper;
  var options = {
    aspectRatio: 1 / 1,
    minContainerWidth: 570,
    minContainerHeight: 450,
    minCropBoxWidth: 145,
    minCropBoxHeight: 145,
    rotatable: true,
    cropBoxResizable: true,
    crop: function(e) {
      $("#cropped_value").val(
          parseInt(e.detail.width) +
          "," +
          parseInt(e.detail.height) +
          "," +
          parseInt(e.detail.x) +
          "," +
          parseInt(e.detail.y) +
          "," +
          parseInt(e.detail.rotate)
      );
    }
  };

  // Show cropper on existing Image
  /*$("body").on("click", "#image_source", function() {
      var src = $("#image_source").attr("src");
      src = src.replace("/thumb", "");
      $('#image_cropper').attr('src', src);
      $('#image_edit').val("yes");
      $("#myModal").modal("show");
  });*/

  // Destroy Cropper on Model Hide
  $("#profileImageUpload").on("hide.bs.modal", function() {
    cropper.destroy();
  });

  // Show Cropper on Model Show
  $("#profileImageUpload").on("show.bs.modal", function() {
    var image = document.getElementById("image_cropper");
    cropper = new Cropper(image, options);
  });

  // Rotate Image
  $("body").on("click", ".rotate", function() {
    var degree = $(this).attr("data-option");
    cropper.rotate(degree);
  });

  // When user upload image
  $(document).on("change", "#avatar", function() {

    var dataAction = $(this).attr("data-type");
    (file = this.files[0]),
        (imagefile = file.type),
        (_URL = window.URL || window.webkitURL);
    img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = function() {
      var match = ["image/jpeg", "image/png", "image/jpg"];
      if (
          !(
              imagefile == match[0] ||
              imagefile == match[1] ||
              imagefile == match[2]
          )
      ) {
        alert("Please Select A valid Image File");
        return false;
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function() {
          // set image data as background of div
          $(document)
              .find("#image_cropper")
              .attr("src", "");
          $(document)
              .find("#image_cropper")
              .attr("src", this.result);
          if(dataAction == 'addAvatar') {
            $("#updateAvatar").attr("data-action","addAvtar");
          } else {
            $("#updateAvatar").attr("data-action","updateAvtar");
          }
          $("#profileImageUpload").modal("show");
        };
      }
    };
  });
});

// Saving avatar
$(document).on("click", "#updateAvatar", function() {
  showModalSpinner();
  var action = $(this).attr("data-action");
  if(action == 'addAvtar') {
    hideModalSpinner();
    $("#profileImageUpload").modal("hide");
  } else {
    $("#FileUpload").submit();
  }
});

// Code to customize html5 error messages
$(document).on("DOMContentLoaded", function() {
  var elements = document.querySelectorAll("input:not([name='amount'])");
  for (var i = 0; i < elements.length; i++) {
    elements[i].oninvalid = function(e) {
      e.target.setCustomValidity("");
      if (!e.target.validity.valid) {
        e.target.setCustomValidity("Vul deze gegevens in");
      }
    };
    elements[i].oninput = function(e) {
      e.target.setCustomValidity("");
    };
  }
});

$(document).on("DOMContentLoaded", function() {
  var elements = document.querySelectorAll("input[name='amount']");
  for (var i = 0; i < elements.length; i++) {
    elements[i].oninvalid = function(e) {
      e.target.setCustomValidity("");
      if (!e.target.validity.valid) {
        e.target.setCustomValidity("Het bedrag voor een rit is "+ $(e.target).data("step") +" euro, vul een veelvoud hiervan in.");
      }
    };
    elements[i].oninput = function(e) {
      e.target.setCustomValidity("");
    };
  }
});

$(document).ready(function() {

  var table = $('table');

  $('#date, #name,.sortth')
      .wrapInner('<span title="sort this column"/>')
      .each(function(){

        var th = $(this),
            thIndex = th.index(),
            inverse = false;

        th.click(function(){

          table.find('td').filter(function(){

            return $(this).index() === thIndex;

          }).sortElements(function(a, b){

            return $.text([a]) > $.text([b]) ?
                inverse ? -1 : 1
                : inverse ? 1 : -1;

          }, function(){

            // parentNode is the element we want to move
            return this.parentNode;

          });

          inverse = !inverse;

        });

      });

});

$(document).ready(function(){

  var winHeight = $(window).height();

  if(winHeight > 480) {
    $("#rapportages-container, #registration-content").css({height:"700px"});
  }

  $(".print-card").click(function(e){
    e.preventDefault();
    console.log("test")
    var phone = $(e.currentTarget).closest("form").find("input[name='mobile']").val()

    if (!phone) {
      $("#no-valid-number").show();
      setTimeout(function () {
        $("#no-valid-number").hide()
      }, 5000);
      return;
    }

    var href = $(this).attr("href");
    $(".card-url").attr("href", href);
    $("#cardInfo").attr("action", href);
    $("#confirmCardModal").modal("show");
  });

  $(".add-payment").click(function(e) {
    e.preventDefault();
    $("#userFullNamePayment").html($("#firstName").val()+" "+$("#lastName").val());
    $("#addPaymentModal").modal();
  });

  $(".add-trip").click(function(e) {
    e.preventDefault();
    $("#userFullNameTrip").html($("#firstName").val()+" "+$("#lastName").val());
    $("#addTripModal").modal();
  });

  $(".card-url").click(function(e){
    e.preventDefault();
    $("#cardInfo").submit();
    setTimeout(function() {
      $("#confirmCardModal").modal("hide");
    }, 1000);
  });


  // Send email to group of user

  $(".sendmail").click(function(e){
    e.preventDefault();
    $("input[name=userIds]").val(JSON.stringify($(".userIds:checked").serializeArray()));
    $("#groupEmailModal").modal("show");
    tinymce.init({
      selector: '#body'
    });

  });

  // Check if mollie is active or not

  $("#mollieActive").on("click", function() {
    if($("#mollieActive:checked").length) {
      $(".star").html("*");
    } else {
      $(".star").html("");
    }
  });

  $(".confirmsendGroupEmail").click(function(e) {
    e.preventDefault();
    tinyMCE.triggerSave();

    var subject = $('#subject').val();
    var body = $('#body').val();

    if(!subject) {
      $(".subject-error").html("Vul alstublieft het onderwerp in.");
    }

    if(!body) {
      $(".body-error").html("Voer hier uw emailbericht in.");
    }

    if(subject && body) {
      $(".error").html(" ");
      $("#groupEmailForm").submit();
    }
  });

  function redirectToOrganisation(target) {
    $(target).on("click", function () {
      self = $(this);
      $.ajax({
        method: "POST",
        url: "/organisatie/selecteer/" + self.data("organisation-id"),
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function (result) {
          window.location.reload();
        },
        error: function (jqXHR, textStatus, errorThrown) {

        }
      });
    });
  }

  redirectToOrganisation("#select-organisation li a");

  $(".edit-user").on("click",function () {
    self = $(this);
    $.ajax({
      method: "POST",
      url: "/organisatie/selecteer/" + self.data("organisation-id"),
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(result) {
        window.open("/overzicht/gebruiker/"+self.data("id"))
      },
      error: function(jqXHR, textStatus, errorThrown) {

      }
    });
  });

  $("#select-meta-organisation li a").on("click",function () {
    self = $(this);
    window.location.href = "/super-admin/meta_organisations/"+self.data("meta-organisation-id");
  });

  $('#organisation-multiselect').multiselect({
    includeSelectAllOption: true,
    buttonWidth: 250,
    enableFiltering: true,
    dropUp: true,
    maxHeight: 500,
    enableCaseInsensitiveFiltering: true,
  });

  $(".copyTextToClipBoard").on("click", function (event) {
    event.preventDefault();
    event.stopPropagation();

    var $temp = $("<input>");
    $("body").append($temp);
    var value = $(this).siblings(".contentToBeCopied").val();
    $temp.val(value).select();
    document.execCommand("copy");
    $temp.remove();

    alert("Copied the text: " + value);
  });

  var table = $(".organisations .location-table").DataTable({
    searchHighlight: true,
    pageLength: 5,
    stateSave: true,
    "language": {
      "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
    },"columnDefs": [
      { "searchable": false, "targets": 4 },
      { "orderable": false, "targets": [1,2,3,4]}
    ],
    "lengthMenu": [ 5, 10, 15, 20, 25, 50, 100 ]
  });

  $(".change-meta-organisation").on("change",function (event) {
      self = $(this);
      var selected = $(this).find(':selected')
      var data = {"meta_organisation_id": selected.data("meta-organisation-id"), "organisation_id": selected.data("organisation-id")};

      $.ajax({
        type: "POST",
        url: "/super-admin/organisation/change_meta_organisation",
        data: data,
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function(result) {
          var value;
          if (result) {
              value = result
          } else {
              value = "Onbekend"
          }
          self.closest("tr").find(".organisation-name").html(value)
        },
        error: function(jqXHR, textStatus, errorThrown) {

        }
      });
  })

  function activation (prefix, type, reload = false) {
    $(".activation-of-" + type).on("click",function (event) {
      self = $(this);
      self.prop('disabled', true);

      var id = type + "-id";
      var data = {};
      data[id] = self.data(id);
      data["value"] = self.data("value");

      $.ajax({
        type: "POST",
        url: "/"+prefix+"/"+type+"/activation",
        data: data,
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function(result) {
          if(self.data("value") === 0) {
            self.next(".enable").show().prop('disabled', false);
            self.hide().prop('disabled', false);
          } else if (self.data("value") === 1) {
            self.prev(".disable").show().prop('disabled', false);
            self.hide().prop('disabled', false);
          }
          if (reload) {
            location.reload();
          }
        },
        error: function(jqXHR, textStatus, errorThrown) {

        }
      });
    })
  }

  activation("super-admin","organisation");
  activation("super-admin","bicycle",true);
  activation("overzicht","subparticipant");

  // On click on user availability button
  $(".location-table .btn-contact-info")
      .unbind("click")
      .bind("click", function() {
        openAvailabilityModal($(this).data("type"), $(this).data("id"));
      });

  $(".remove-meta-organisation").on("click", function () {
       var modal = $("#deleteMetaOrganisation");
       modal.find("form").attr("action",window.location.origin+"/super-admin/meta_organisation/delete");
       modal.find("form [name='id']").attr("value",$(this).data("id"));
       modal.find(".modal-body").html("Weet je zeker dat je '"+$(this).data("name")+"' wil verwijderen?")
  });

  checkInputsSetCollapse = function (id) {
      if (typeof $(id) != "undefined") {
        if($(id+" input:checkbox:checked").length > 0) {
          $(id).collapse('show');
        }
      }
  }

  preventDoubleSubmission = function (id) {
    $(id).on("click", function (event) {
      event.preventDefault();
      $(this).prop("disabled","disabled");
      $(this).closest("form").submit();
      return false;
    });
  }

  preventDoubleSubmission("#add-bicycle");
  preventDoubleSubmission("#add-subparticipant");

  $("#add-payment-button").on("submit",function (event) {
    event.preventDefault();

    var form = $(this).closest("form");
    var tripPrice = form.find("input[name='amount']").data("step");
    var value = form.find("input[name='amount']").val();

    if (value % tripPrice === 0) {
      form.submit();
    }
  })

  $('.panel-collapse').on('show.bs.collapse', function () {
    $(this).siblings('.panel-heading').addClass('active');
  });

  $('.panel-collapse').on('hide.bs.collapse', function () {
    $(this).siblings('.panel-heading').removeClass('active');
  });

  $('#appointmentModal').on('shown.bs.modal', function () {
    $('#appointmentModal textarea').trigger("keyup.dynSiz")
  })

  $('#requestModal').on('shown.bs.modal', function () {
    $('#requestModal textarea').trigger("keyup.dynSiz")
  })

  $('#notitieModal').on('shown.bs.modal', function () {
    $("#notitieModal textarea").trigger("keyup.dynSiz")
  })

  $('textarea').autoResize()
  $('textarea').trigger("keyup.dynSiz")
});